import React from 'react';
import { Jumbotron } from 'react-bootstrap';

function Home() {
    return (
        <>
        <Jumbotron style={{ background: 'transparent' }} className="d-flex align-items-center justify-content-center">
            <img
                src={`${process.env.PUBLIC_URL}/logo512.png`}
                alt="logo" />
        </Jumbotron>
        </>
    )
}

export default Home;