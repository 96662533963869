import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
// import props from 'prop-types';

function RedistricterCarousel() {

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`${process.env.PUBLIC_URL}/images/redistricter-1.jpg`}
                        alt="slide"
                        height='500'
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`${process.env.PUBLIC_URL}/images/redistricter-2.jpg`}
                        alt="slide"
                        height='500'
                    />
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`${process.env.PUBLIC_URL}/images/redistricter-3.jpg`}
                        alt="slide"
                        height='500'
                    />
                </Carousel.Item>

        </Carousel>
    );
}

export default RedistricterCarousel;