import React from 'react';
import { Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { GitHub, Mail } from 'react-feather';

function DWNavbar() {
    return (
        <Navbar bg="light" variant="light" sticky="top" expand="lg" className="w-100">
            <LinkContainer to="/">
                <Navbar.Brand>Dancing Weasels</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <LinkContainer to="/projects">
                        <Nav.Link>Projects</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/resume">
                        <Nav.Link>Resume</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/about">
                        <Nav.Link>About</Nav.Link>
                    </LinkContainer>
                </Nav>
                <Nav className="flex-row flex-nowrap">
                    <Nav.Link className="pr-4" href="mailto:calabria.daniel@gmail.com">
                        <Mail className="dark" />
                    </Nav.Link>
                    <Nav.Link className="text-nowrap" href="https://github.com/syndir">
                        <GitHub className="lightgrey" />
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default DWNavbar;