import React from 'react';
import { Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';

export default function Footer() {
    return (
        // <div className="fixed-bottom d-flex justify-content-center dark">
        //     <span className="small text-dark">This site was created with React and Bootstrap. Copyright &copy; 2021 Daniel Calabria.</span> 
        // </div>
        // <footer class="footer small">
        //     <span >This site was created with React and Bootstrap. Copyright &copy; 2021 Daniel Calabria.</span>
        // </footer>
        <Navbar bg="light" variant="light" fixed="bottom" expand="lg" className="w-100 justify-content-center">
            <Nav.Item className='small dark'>
                <span className='justify-content-center'>This site was created with React and Bootstrap. Copyright &copy; 2021 Daniel Calabria.</span>
            </Nav.Item>
        </Navbar>
    );
}

export function NonStickyFooter() {
    return (
        <div className="d-flex justify-content-center dark">
            <span className="small text-dark">This site was created with React and Bootstrap. Copyright &copy; 2021 Daniel Calabria.</span> 
        </div>
    );
}