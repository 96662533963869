import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function About() {
    return (
        <div>
            <Container>
                <Row>&nbsp;</Row>
                <Row className="justify-content-center">
                    <h1><span >Hi! I'm Daniel Calabria.</span></h1>
                </Row>
                <Row className="justify-content-center">
                    <h3>
                        I am a software developer based on eastern Long Island in New York.
                    </h3>
                </Row>
                <Row className="text-center">
                    <Col>
                        I am interested in systems and cybersecurity, sci-fi, fantasy, watching horrible drama shows on Netflix, astronomy, and wood-working.
                        I enjoy taking things apart to see how they work, and putting things together to make something more interesting than the sum of its pieces.
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default About;