import React from 'react';
import { Alert, Container, Row, Col, Tab, ListGroup } from 'react-bootstrap';

function Resume() {
    return (
        <Container>
            <Row>&nbsp;</Row>
            <Tab.Container id="about-tab-container" defaultActiveKey="#education">
                <Row>
                    <Col sm={4}>
                        <ListGroup>
                            <ListGroup.Item variant="light" action href="#education">
                                <span style={{ fontVariant: 'small-caps' }}>Education</span>
                            </ListGroup.Item>
                            <ListGroup.Item variant="light" action href="#languages">
                                <span style={{ fontVariant: 'small-caps' }}>Languages</span>
                            </ListGroup.Item>
                            <ListGroup.Item variant="light" action href="#othertech">
                                <span style={{ fontVariant: 'small-caps' }}>Other Technologies</span>
                            </ListGroup.Item>
                            <ListGroup.Item variant="light" action href="#experience">
                                <span style={{ fontVariant: 'small-caps' }}>Experience</span>
                            </ListGroup.Item>
                        </ListGroup>
                        <Alert.Link href="/files/resume.pdf" className="float-right text-dark">Download Resume as PDF</Alert.Link>
                    </Col>

                    <Col sm={8}>
                        <Tab.Content>
                            <Tab.Pane eventKey="#education">
                                <Row>
                                    <Col>
                                        <em>Stony Brook University</em>
                                    </Col>
                                    <Col className="float-right">
                                        <span className="float-right">2018 to 2020</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                Majored in <b>Computer Science</b>.
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Graduated <em>Summa Cum Laude</em>.
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#languages">
                                <Row>
                                    <Col>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                C
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                C++
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                C#
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Java
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Javascript
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Python
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                PHP
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Ruby
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                UNIX Shell Scripting
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#othertech">
                                <Row>
                                    <Col>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Android</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Apache</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Bootstrap</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Criterion</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">GDB</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">GIS</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Git</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">GraphQL</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">HTML</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Jackson</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Java EE</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">JPA</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">JSON</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">JUnit</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">LAMP</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">LaTeX</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Linux</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Material-UI</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">MERN</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Mockito</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">MongoDB</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">MySQL</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Node.js</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Nose</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">NPM</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">React</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">REST APIs</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Ruby on Rails</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Spring</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Spring Boot</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Tomcat</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Valgrind</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Windows</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0">Yarn</ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0"></ListGroup.Item>
                                            <ListGroup.Item className="w-25 border-top-0 border-left-0 border-right-0"></ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#experience">
                                <Row>
                                    <Col md="auto">
                                        <span><em>Teaching Assistant - Data Structures (Stony Brook University)</em></span>
                                    </Col>
                                    <Col className='float-right'>
                                        <span className='float-right'>
                                            2019 to 2020
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="auto">
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                Instructed recitation sections and review sessions.
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Assisted students with materials during office hours.
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Created and designed homework documents, specifications, and UML diagrams
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>

                                <Row>
                                    <Col>
                                        <span><em>Support Manager (Walmart)</em></span>
                                    </Col>
                                    <Col className='float-right'>
                                        <span className='float-right'>
                                            2004 to 2017
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                Responsible for over 50 associates throughout the store, providing them with leadership, direction, and feedback and engaging with them as a team.
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Provided a high level of customer service and satisfaction in a fast-paced, high-volume, and deadline-driven environment.
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Performed inventory management tasks to ensure in-stock of items in order to maximum sales and reduce out-of-stocks.
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                Planned and executed the Yearly Event (Black Friday). This involved the logistical planning of the event,
                                                determining how to prepare the merchandise for secure storage, preparing materials and plans for the store to ensure a flawless
                                                execution of setup and teardown while minimizing disruptions to normal business, and determining proper placement of event
                                                merchandise to limit liabilities, all while remaining within the guidelines set forth by NY state and Walmart home office. This also involved
                                                the thorough training of all associates who would be involved with the event to ensure that each associate understood their role.
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    );
}

export default Resume;