import React from 'react';
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import RedistricterCarousel from './RedistricterCarousel';
import GologoloCarousel from './GologoloCarousel';

function Projects() {

    return (
        <Container>
            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Redistricter</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A Congressional District Generation and Bias Analysis Tool</Card.Subtitle>
                            <Container className="w-100 justify-content-center">
                                <RedistricterCarousel />
                            </Container>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>Java, Python, JavaScript, Spring, Shapely, Fiona, GIS, MySQL, JPA, Tomcat, Jackson</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                A tool which can be used to create sets of randomly generated congressional district plans. These plans can then be
                                viewed and compared against the currently enacted plan to analyze any gerrymandering within the state attempting 
                                to discount the votes of demographic minorities.
                            </Card.Text>
                            <Card.Text>
                                Data was gathered through the US Census Bureau for 2010 and processed using Python. The district generation algorithm
                                executes on the SeaWulf high performance cluster.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/redistricter" className="small text-dark">
                                Source
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>SMASH Server</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A job server that allows for queued execution with specified resource limits</Card.Subtitle>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>C, Linux, UNIX Domain Sockets</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                Users may submit jobs into a queue for execution with specified resource limits. The user may specify
                                certain CPU time limits and memory usage limits. If a job exceeds these specified thresholds, it will be
                                terminated. Jobs are executed concurrently, up to a predefined maximum number of jobs.
                            </Card.Text>
                            <Card.Text>
                                A client is also provided for interaction with the server. Clients may receive asynchronous notifications about job
                                status updates. The client may also download the results of the job and any error output, as desired.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/SMASHServer" className="small text-dark">
                                Source
                            </Card.Link>
                            <Card.Link href="/files/smashserver-design.pdf" className="small text-dark">
                                Design Document
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>SMASH (SMAll SHell)</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A small shell for UNIX systems</Card.Subtitle>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>C, Linux</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                A small and simple shell for UNIX systems. Supports foreground and background execution,
                                IO redirection, pipelining, environment variables, and interaction and non-interactive modes.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/SMASH" className="small text-dark">
                                Source
                            </Card.Link>
                            <Card.Link href="/files/smash-design.pdf" className="small text-dark">
                                Design Document
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>eunuchs</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A Linux kernel-level rootkit</Card.Subtitle>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>C, Linux</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                A kernel level rootkit for Linux x32 devices. Targets Debian 10.1.0 x86 and kernel 4.19.67-2+deb10u1, specifically, although this should be easily
                                modifiable to target other versions, as well. Allows for the hiding of processes by PID and the hiding of files by specified extensions. It also
                                offers multiple methods of credential elevation, as well as injecting a backdoor account into the system for future access.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/eunuchs" className="small text-dark">
                                Source
                            </Card.Link>
                            <Card.Link href="/files/eunuchs-design.pdf" className="small text-dark">
                                Design Document
                            </Card.Link>
                            <Card.Link href="/files/eunuchs-slides.pdf" className="small text-dark">
                                Presentation Slides
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>ROPe</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Return Oriented Payload executor</Card.Subtitle>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>Python, GDB, Capstone, pwntools, C, ASM, Linux</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                A Python script which attempts to perform return oriented programming to exploit 64-bit Linux ELF files and to execute a second-stage shellcode.
                                By searching through an ELF file (and any other files which it is linked against), the script will assemble a ROP chain which will then be used
                                to execute some other supplied payload. ROPe targets programs which take unchecked user input during runtime through <code>read(2)</code>,&nbsp;
                                <code>gets(3)</code>, and <code>scanf(3)</code>.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/ROPe" className="small text-dark">
                                Source
                            </Card.Link>
                            <Card.Link href="/files/ROPe-design.pdf" className="small text-dark">
                                Design Document
                            </Card.Link>
                            <Card.Link href="/files/ROPe-slides.pdf" className="small text-dark">
                                Presentation Slides
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

 
            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Bourse Exchange Server</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A multi-threaded networked trade server</Card.Subtitle>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>C, pthreads, Linux</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                A network server which can be used to effectuate trades between users via matchmaking. Utilizes threading through <code>pthreads</code>, semaphores, 
                                and mutexes to allow for concurrency among users. 
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>

                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>ScoreKeeper</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A simple score keeping app for Android</Card.Subtitle>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>Java, Android</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                An Android based application which can be used in place of pen-and-paper for keeping track of scores during
                                games. Allows for individual and team-based score keeping.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/ScoreKeeper" className="small text-dark">
                                Source
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Row>&nbsp;</Row>
            <Row className="w-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>goLogolo</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">A logo creation toolkit</Card.Subtitle>
                            <Container className="w-100 justify-content-center">
                                <GologoloCarousel />
                            </Container>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Container className="fluid">
                                        <Row>
                                            <Col className="col-sm-auto mr-auto">Technologies Used</Col>
                                            <Col className="col-sm-auto ml-auto"><em>MERN Stack, JavaScript, Node.js, HTML, CSS, Material-UI, MongoDB, GraphQL, Apollo, Passport.js, Express</em></Col>
                                        </Row>
                                    </Container>
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                Create logos with text and images! Uses Google OAuth for user authentication and persists the logos in a database using MongoDB and GraphQL.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href="https://github.com/syndir/gologolo" className="small text-dark">
                                Source
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
        </Container>
    )
}

export default Projects;